import Link from "next/link";
import 유투브_이미지 from "/public/assets/pc/images/icon/icon_youtube1.webp";
import 블로그_이미지 from "/public/assets/pc/images/icon/icon_blog1.webp";
import 플레이스토어_이미지 from "/public/assets/pc/images/btn/btn_googlestore2.webp";
import 애플스토어_이미지 from "/public/assets/pc/images/btn/btn_appstore2.webp";
import 원격지원_이미지 from "/public/assets/pc/images/icon/icon_computer1.webp";
import { useContext } from "react";
import { 회사정보Context } from "./데이터/회사정보";
import { CSR } from "./CSR";
import { 링크 } from "./데이터/링크";
import { Env } from "./Env";
import PrizeBrand2021 from "/public/assets/pc/images/pic/pc_brand.png";
import PrizeVenture2022 from "/public/assets/pc/images/pic/pc_venture.png";
import Image from "next/image";

export const 푸터_Desktop = () => {
  const 회사정보 = useContext(회사정보Context);
  return (
    <div>
      <div id="footer-banner" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
        <center>
          <Image
            src={PrizeBrand2021}
            alt="2021 올해의 우수 브랜드 대상 수상"
          />
          <Image
            src={PrizeVenture2022}
            alt="2018 벤처 기업 선정"
          />      
        </center>  
      </div>
      <div id="footer">
        <div className="inner">
          <div className="copyright">
            <p>
              <span>{회사정보.회사명}</span>
              <span>대표이사 : {회사정보.대표이사}</span>
              <span>
                사업장 주소 : {회사정보.주소.건물주소}, {회사정보.주소.상세주소}
              </span>
              <span>대표번호 : {회사정보.대표번호}</span>
              <span>{회사정보.이메일}</span>
              <CSR>
                {/* cloudflared의 email obfuscation떄문에 CSR로 두었습니다. 최종 배포시엔 수정할 예정 */}
                <span>개인정보담당자 : {회사정보.개인정보담당자}</span>
              </CSR>
              <span>사업자등록번호 : {회사정보.사업자등록번호}</span>
              <span>통신판매업신고 : {회사정보.통신판매업신고번호}</span>
            </p>
            <p>
              <span>Copyright©marketboro Co., Ltd. All Rights Reserved.</span>
            </p>
          </div>
          <div className="sns-app">
            <div className="sns">
              <p>마켓봄 SNS</p>
              <Link
                href={링크.Youtube}
                className="btn-youtube"
                style={{
                  background: `url(${유투브_이미지.src}) no-repeat center top`,
                }}
              >
                Youtube
              </Link>
              <Link
                href={링크.Blog}
                className="btn-blog"
                style={{
                  background: `url(${블로그_이미지.src}) no-repeat center top`,
                }}
              >
                Blog
              </Link>
            </div>
            <div className="app-download">
              <Link
                href={링크.거래채앱_구글플레이}
                className="btn-googleplay"
                style={{
                  background: `url(${플레이스토어_이미지.src}) no-repeat center center #2d2e2e`,
                }}
              >
                googleplay
              </Link>
              <Link
                href={링크.거래채앱_앱스토어}
                className="btn-appstore"
                style={{
                  background: `url(${애플스토어_이미지.src}) no-repeat center center #2d2e2e`,
                }}
              >
                appstore
              </Link>
              <Link
                href={링크.원격상담신청}
                target="_blank"
                className="btn-remote-control"
                style={{
                  background: `url(${원격지원_이미지.src}) no-repeat center center `,
                }}
              >
                원격상담신청
              </Link>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            height: 0,
          }}
        >
          {Env.APP_VERSION}
        </div>
      </div>
    </div>
  );
};
